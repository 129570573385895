<template>
  <validation-observer ref="simpleRules">
    <b-card no-body class="p-2">
      <b-form enctype="multipart/form-data" method="post">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-button-group class="w-100">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-bind:class="[form.user_type == 'seller' ? 'active' : '']"
                  v-on:click="changeUserType('seller')"
                >
                  SELLER
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-bind:class="[form.user_type == 'admin' ? 'active' : '']"
                  v-on:click="changeUserType('admin')"
                  variant="outline-primary"
                >
                  ADMIN
                </b-button>
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- First Name -->
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.user_fname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="First Name"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Last Name -->
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.user_lname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Last Name"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Email -->
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  v-model="form.user_email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Phone -->
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-form-input
                  v-model="form.user_phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Phone"
                  type="number"
                  max="13"
                  step="0"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Username -->
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  v-model="form.user_username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Username"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Password -->
          <b-col md="4">
            <b-form-group>
              <!-- <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              > -->
              <!-- <b-form-input
                v-model="form.user_password"
                :state="errors.length > 0 ? false : null"
                placeholder="Password"
                type="password"
              /> -->
              <b-form-input
                v-model="form.user_password"
                placeholder="Password"
                type="text"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              <!-- </validation-provider> -->
            </b-form-group>
          </b-col>

          <!-- Date Of Birth -->
          <b-col md="4">
            <b-form-group>
              <b-form-datepicker
                id="example-datepicker"
                v-model="form.user_dob"
                placeholder="Date of Birth"
                class="mb-1"
              />
            </b-form-group>
          </b-col>
          <!-- Country -->
          <b-col md="4">
            <v-select
              id="countries-select"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              v-model="selectedCountry"
              :options="countries"
              @input="onSelectCountry"
              placeholder="Select Country"
            />
          </b-col>

          <!-- Status -->
          <b-col md="4">
            <div class="demo-inline-spacing">
              <label for="" class="status">Status (Active / In-Active)</label>
              <b-form-checkbox
                checked="true"
                class="custom-control-success status"
                name="check-button"
                switch
                v-model="form.user_status"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="2">
            <b-button
              variant="success"
              type="submit"
              class="btn-block"
              @click.prevent="onSubmit()"
            >
              Save User
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCard,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BFormTextarea,
  BFormRadio,
  BInputGroup,
  BButtonGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormDatepicker,
  BImg,
  BFormFile,
  BCollapse,
  VBToggle,
  VBPopover,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DropDownServices from "@/apiServices/DropDownServices";
import UserManagementServices from "@/apiServices/UserManagementServices";
import ServiceServices from "@/apiServices/ServiceServices";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BCard,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormTextarea,
    BFormRadio,
    BButtonGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormDatepicker,
    BImg,
    BFormFile,
    BCollapse,
    BFormSelect,
    BFormCheckbox,
    VBPopover,
    VBToggle,

    vSelect,
  },
  setup() {},
  beforeMount() {
    this.loadCountry();
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-popover": VBPopover,
  },
  computed: {},
  data() {
    return {
      countries: [],
      selectedCountry: null,
      form: {
        user_id: null,
        user_email: null,
        user_password: null,
        user_fname: null,
        user_lname: null,
        user_phone: null,
        user_dob: null,
        user_country: null,
        user_username: null,
        user_type: null,
        user_status: null,
      },
      required,
      email,
      confirmed,
      password,
    };
  },
  methods: {
    changeUserType(user_type) {
      this.user_type = user_type;
    },
    async loadCountry() {
      let response = await DropDownServices.loadAllCountries();
      this.countries = response.data;
    },
    onSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.form.user_id && !this.form.user_password) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Password Not set",
                icon: "EditIcon",
                variant: "failure",
              },
            });
            return;
          }
          UserManagementServices.saveUser(this.form)
            .then((response) => {
              if (response.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: "UserCheckIcon",
                    variant: "success",
                  },
                });

                this.$router.push("/admin-user-management");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: "UserXIcon",
                    variant: "error",
                  },
                });
              }
            })
            .catch((error) => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
        }
      });
    },
    onSelectCountry(e) {
      this.form.user_country = e.name;
    },
    async getDetails() {
      if (!this.form.user_id) {
        return;
      }

      try {
        const response = await UserManagementServices.getSingleUser({
          user_id: this.form.user_id,
        });
        if (response.data.status) {
          this.form = response.data.data;
          this.form.user_status = this.form.user_status ? true : false;
          if (this.form.user_country && this.countries.length > 0) {
            let country_arr = this.countries;
            for (let i = 0; i < country_arr.length; i++) {
              const country = country_arr[i];
              if ((country.name = this.form.user_country)) {
                this.selectedCountry = country;
              }
            }
          }
        }
      } catch (err) {
        console.log("Error in getting user detials ", err);
      }
    },
  },
  beforeMount() {
    this.loadCountry();
    const { user_id } = this.$route.params;
    if (user_id && !isNaN(user_id)) {
      this.form.user_id = user_id;
      this.getDetails();
    }
  },
};
</script>

<style>
.custom-container {
  position: relative;
}

.custom-flex {
  display: -webkit-box;
}

.per-page-selector {
  width: 90px;
}

.vs__dropdown-toggle > .vs__open-indicator {
  fill: grey !important;
}

[dir="ltr"] .vs__actions > svg {
  fill: grey !important;
}

.status {
  margin-top: 10px !important;
  padding-top: 0px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
